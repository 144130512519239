import React from "react";

import { useI18next } from "gatsby-plugin-react-i18next";

import Section from "../../Section";

import {Videos} from "../components/videos";

export const GuideVideos = () => {
	const { language } = useI18next();

	// Only show the divs if the language is Estonian ('et')
    if (language !== 'et') {
        return null;
    }

	return (
		<Section title="Tutvustavad lühivideod" id="guide-videos">
			<Videos/>
		</Section>
	)
}